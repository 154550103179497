import React, { createContext, PropsWithChildren, useContext } from 'react';
import { Attendee, Identifier, Package, RoomOccupancy, UUID } from './types';
import type { AttendeesApi, AttendeeUpdate } from './api/attendees';
export type { AttendeeUpdate } from './api/attendees';

type AttendeesContext = {
  getAttendees: (conference: Identifier) => Promise<Attendee[]>;
  updateAttendee: (attendee: Attendee) => Promise<void>;
  deleteAttendee: (id?: UUID) => Promise<void>;
  resetAccount: (conference: Identifier, email?: string) => Promise<void>;
  resendConfirmation: (conference: Identifier, email?: string) => Promise<void>;
  changeAttendeeDetails: (
    conference: Identifier,
    email: string,
    update: AttendeeUpdate,
  ) => Promise<void>;
  changeBooking: (
    conference: Identifier,
    email: string,
    update: { packages?: Package[]; room?: Partial<RoomOccupancy> },
  ) => Promise<void>;
  lockProfiles: (conferenceId: UUID, attendees: Attendee[]) => Promise<void>;
  downloadBadgesXML: (conference: Identifier) => Promise<void>;
  downloadCsv: (conference: Identifier) => Promise<void>;
  downloadStripe: (conference: Identifier) => Promise<void>;
  createInvoice: (id?: UUID) => Promise<void>;
};

const DEFAULT_CONTEXT: AttendeesContext = {
  getAttendees: (_conference: Identifier) => {
    console.log('not getting attendees');
    return Promise.resolve([]);
  },
  updateAttendee: async (attendee) => {
    console.log('not updating', attendee);
  },
  deleteAttendee: async (id?: UUID) => {
    console.log('not deleting', id);
  },
  resetAccount: async (conference: Identifier, email?: string) => {
    console.log('not resetting account', email);
  },
  resendConfirmation: async (conference: Identifier, email?: string) => {
    console.log('not resending confirmation', conference, email);
  },
  changeAttendeeDetails: async (conference, email, update) => {
    console.log(
      'not changing attendee details',
      conference,
      email,
      JSON.stringify(update),
    );
  },
  lockProfiles: async (
    conferenceId: UUID,
    attendees: Attendee[],
  ): Promise<void> => {
    console.log('not locking profiles', conferenceId, attendees);
  },
  changeBooking: async (
    conference: Identifier,
    email: string,
    update: { packages?: Package[]; room?: Partial<RoomOccupancy> },
  ) => {
    console.log(
      'not changing booking',
      conference,
      email,
      JSON.stringify(update),
    );
  },
  downloadBadgesXML: async (_: Identifier) => {
    console.log('not downloading XML');
  },
  downloadCsv: async (_: Identifier) => {
    console.log('not downloading a CSV');
  },
  downloadStripe: async (_: Identifier) => {
    console.log('not downloading a Stripe JSON');
  },
  createInvoice: async (id?: UUID) => {
    console.log('not creating invoice', id);
  },
};
const context = createContext(DEFAULT_CONTEXT);
export const useAttendees = (): AttendeesContext => useContext(context);

type ProvideAttendeesProps = {
  attendeesApi: AttendeesApi;
};

export const AttendeesProvider = ({
  attendeesApi,
  children,
}: PropsWithChildren<ProvideAttendeesProps>): React.ReactElement => {
  const attendees = {
    deleteAttendee: attendeesApi.deleteAttendee,
    updateAttendee: attendeesApi.updateAttendee,
    getAttendees: attendeesApi.getAttendees,
    resetAccount: attendeesApi.resetAccount,
    resendConfirmation: attendeesApi.resendConfirmation,
    changeAttendeeDetails: attendeesApi.changeAttendeeDetails,
    changeBooking: attendeesApi.changeBooking,
    lockProfiles: attendeesApi.lockProfiles,
    downloadBadgesXML: attendeesApi.downloadBadgesXML,
    downloadCsv: attendeesApi.downloadCsv,
    downloadStripe: attendeesApi.downloadStripe,
    createInvoice: attendeesApi.createInvoice,
  };
  return <context.Provider value={attendees}>{children}</context.Provider>;
};
