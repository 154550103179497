import React, { useEffect, useState } from 'react';
import './EventsApplicantsList.scss';
import { PagingTable, RowMapper } from '../../table/PagingTable';
import {
  EmptyEventsApplicantsListRow,
  EventsApplicantsListRow,
} from './EventsApplicantsListRow';

import { Applicant, Conference, RoomTypeName, UUID } from '../../main/types';
import { useApplicants } from '../../main/ApplicantsProvider';
import { useConference } from '../../main/ConferenceProvider';
import { ModalMessage } from '../../modal/ModalMessage';
import { MessageType } from '../../modal/messages';

const ApplicantsTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Name</th>
    <th>Email</th>
    <th>Diversity</th>
    <th>Requested FinancialAid</th>
    <th>Roommate</th>
    <th>Actions</th>
  </tr>
);

const ApplicantsHeader = (): React.ReactElement => (
  <div>
    <h5 className="mb-0">Event applicants</h5>
  </div>
);

export const EventsApplicantsList = (): React.ReactElement => {
  const conferenceContext = useConference();
  const applicantContext = useApplicants();
  const [conference, setConference] = useState<Conference | undefined>();
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [roommates, setRoommates] = useState<string[]>([]);
  const [messageType, setMessageType] = useState<MessageType | undefined>(
    undefined,
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    let isSubscribed = true;
    if (!conference) {
      if (conferenceContext.current) setConference(conferenceContext.current);
      else {
        conferenceContext.choices().then((c) => {
          if (isSubscribed) {
            setConference(c[0]);
          }
        });
      }
    } else {
      applicantContext.getApplicants(conference.id).then((a) => {
        if (isSubscribed) {
          setApplicants(a);
          setRoommates(
            a
              .filter((a) => a.roommate !== undefined)
              .map((a) => a.roommate ?? ''),
          );
        }
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [applicantContext, conferenceContext, conference]);

  const onDelete = async (id?: UUID): Promise<void> => {
    if (conference) {
      await applicantContext.deleteApplicant(conference.id, id);
      setApplicants(await applicantContext.getApplicants(conference.id));
    }
  };

  const forward = async (
    index?: number,
    reason?: string,
    roomType?: RoomTypeName,
  ): Promise<void> => {
    await applicantContext.moveToAttendees(index, reason, roomType);
    if (conference)
      setApplicants(await applicantContext.getApplicants(conference.id));
  };

  const onForwardSponsor =
    (index?: number) =>
    async (roomType: RoomTypeName): Promise<void> => {
      await forward(index, 'sponsor', roomType).catch((e) => {
        setMessageType(MessageType.FORWARD_FAILED);
        setMessage(
          'Could not forward the selected applicant due to: ' + e.message,
        );
      });
      hidePopover();
    };

  const onForwardStaff = (index?: number) => async (roomType: RoomTypeName) => {
    await forward(index, 'staff', roomType).catch((e) => {
      setMessageType(MessageType.FORWARD_FAILED);
      setMessage(
        'Could not forward the selected applicant due to: ' + e.message,
      );
    });
    hidePopover();
  };

  const onForwardAttendee =
    (index?: number) => async (roomType: RoomTypeName) => {
      await forward(index, '', roomType).catch((e) => {
        setMessageType(MessageType.FORWARD_FAILED);
        setMessage(
          'Could not forward the selected applicant due to: ' + e.message,
        );
      });
      hidePopover();
    };

  const hidePopover = (): void => document.body.click();

  const applicantsMapper: RowMapper = (row): React.ReactElement => {
    return (
      <EventsApplicantsListRow
        key={row.index}
        applicant={row as Applicant}
        roomTypes={conference?.roomTypes ?? []}
        roommates={roommates}
        onDelete={onDelete}
        onForwardAttendee={onForwardAttendee(row.index)}
        onForwardSponsor={onForwardSponsor(row.index)}
        onForwardStaff={onForwardStaff(row.index)}
      />
    );
  };

  const onClose = async (): Promise<void> => {
    setMessageType(undefined);
    setMessage('');
  };

  return (
    <>
      <PagingTable
        rows={applicants}
        className={'applicants ' + (conference !== undefined ? '' : 'd-none')}
        listHeader={<ApplicantsHeader />}
        tableHeader={<ApplicantsTableHeader />}
        emptyRow={<EmptyEventsApplicantsListRow />}
        rowMapper={applicantsMapper}
      />
      {messageType !== undefined && (
        <ModalMessage
          dialogAction={onClose}
          messageType={messageType}
          messageHtml={message}
        />
      )}
    </>
  );
};
