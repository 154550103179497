const UI_ENDPOINT: string = 'http://localhost:3000';
const WEB_CLIENT_ENDPOINT: string = 'https://www.socrates-conference.de';
const API_ENDPOINT: string = 'https://api.socrates-conference.de';
// noinspection SpellCheckingInspection
const AUTH_ENDPOINT: string =
  'socrates-admin.auth.eu-central-1.amazoncognito.com';
const WEB_PORTAL = 'https://socrates-conference.awsapps.com/start/#';

// noinspection SpellCheckingInspection
export const config = {
  logoPath: WEB_CLIENT_ENDPOINT,
  apiEndpoints: {
    newsletters: API_ENDPOINT,
    sponsors: API_ENDPOINT,
    slots: API_ENDPOINT,
    subscribers: API_ENDPOINT,
    templates: API_ENDPOINT,
    conferences: API_ENDPOINT,
    events: API_ENDPOINT,
    applicants: API_ENDPOINT,
    attendees: API_ENDPOINT,
    booking: API_ENDPOINT,
  },
  auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_xqIxyGoeN',
    userPoolWebClientId: '109etmanb9ucukgo9l25s6sams',
    oauth: {
      domain: AUTH_ENDPOINT,
      scope: ['email', 'openid'],
      redirectSignIn: UI_ENDPOINT,
      redirectSignOut: WEB_PORTAL,
      responseType: 'token',
    },
  },
};
