import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useEvents } from '../main/EventsProvider';
import { Conference, LoggableEvent } from '../main/types';
import { useConference } from '../main/ConferenceProvider';
import { Button, Table } from 'react-bootstrap';
import { prettifyCommand } from '../common/transform';
import {
  deriveApplicantDataset,
  deriveApplicantLabels,
  deriveApplicantProcesses,
  deriveAttendeeDataset,
  deriveAttendeeLabels,
  deriveAttendeeProcesses,
  deriveOrganizerProcesses,
  deriveTable,
} from './operations-presentation';

export const OperationsDashboard = (): React.ReactElement => {
  const conference = useConference();
  const eventContext = useEvents();

  const [conf, setConf] = useState<Conference | undefined>();
  const [events, setEvents] = React.useState<LoggableEvent[]>([]);
  const [time, setTime] = useState<'24hrs' | '7days'>('24hrs');

  useEffect(() => {
    let isSubscribed = true;
    if (conf === undefined) {
      if (conference.current) setConf(conference.current);
      else {
        conference
          .choices()
          .then((c) => isSubscribed && setConf(c[0]))
          .catch((err) => console.error(err));
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [conference, conf]);

  useEffect(() => {
    if (events.length === 0) {
      eventContext
        .getEvents()
        .then((result) => {
          setEvents(result);
        })
        .catch((err) => console.error(err));
    }
  }, [eventContext, events]);

  const attendeeLabels = deriveAttendeeLabels(events);
  const attendeeProcesses = deriveAttendeeProcesses(events);
  const attendeeData = deriveAttendeeDataset(attendeeProcesses);

  const applicantLabels = deriveApplicantLabels(events);
  const applicantProcesses = deriveApplicantProcesses(events);
  const applicantData = deriveApplicantDataset(applicantProcesses);

  const organizerProcesses = deriveOrganizerProcesses(events);
  const organizerData = deriveTable(organizerProcesses);

  const filterTime = (period: '24hrs' | '7days') => {
    setTime(period);
  };
  const buttonSelection = (selection: '24hrs' | '7days'): '24hrs' | '7days' => {
    return time === '24hrs' ? '7days' : '24hrs';
  };

  function periodLabel(selection: '24hrs' | '7days'): string {
    return selection === '24hrs' ? 'last 24 hours' : 'last 7 days';
  }

  return (
    conference && (
      <div className={`container dashboard ${events ? '' : 'd-none'}`}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <div className={'card mt-4'}>
              <div className={'row card-header'}>
                <div className={'col-6 align-content-center'}>
                  {conf?.title + ' ' + conf?.year} Operations (
                  {periodLabel(time)}){' '}
                </div>
                <div className={'col-6 text-right'}>
                  <Button onClick={() => filterTime(buttonSelection(time))}>
                    {periodLabel(buttonSelection(time))}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-lg-4 col-md-6 col-sm-12'}>
            <div className={'card mt-4'}>
              <div className={'card-header'}>Attendee Actions</div>
              <div className={'card-body p-4 text-center'}>
                <Doughnut
                  data={{ labels: attendeeLabels, datasets: attendeeData }}
                />
                <h2>Total: {attendeeProcesses.length}</h2>
              </div>
              <div className={'card-footer'}></div>
            </div>
          </div>
          <div className={'col-lg-4 col-md-6 col-sm-12'}>
            <div className={'card mt-4'}>
              <div className={'card-header'}>Applicant Actions</div>
              <div className={'card-body p-4 text-center'}>
                <Doughnut
                  data={{ labels: applicantLabels, datasets: applicantData }}
                />
                <h2>Total: {applicantProcesses.length}</h2>
              </div>
              <div className={'card-footer'}></div>
            </div>
          </div>
          <div className={'col-lg-4 col-md-6 col-sm-12'}>
            <div className={'card mt-4'}>
              <div className={'card-header'}>Organizer Actions</div>
              <div className={'card-body p-4 text-center'}>
                <Table striped bordered hover className="actiontable m-0">
                  <tbody>
                    {organizerData.map((value) => (
                      <tr>
                        <td className="text-left">
                          {prettifyCommand(value[0])}
                        </td>
                        <td className="text-right">{value[1][0]}</td>
                        <td className="text-right text-warning">
                          {value[1][1]}
                        </td>
                        <td className="text-right text-danger">
                          {value[1][2]}
                        </td>
                        <td className="text-right text-info">{value[1][3]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <h2 className="pt-3">Total: {organizerProcesses.length}</h2>
              </div>
              <div className={'card-footer'}></div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
